<template>
  <!-- <div class="vlt-loading">
    <img
      v-if="!isEdge"
      src="@/assets/icons/loading.svg"
      class="loading-ico"
      alt="loader"
    />
    <div v-else class="alternateLoader"></div>
    <
  </div> -->
  <div>
    <FeaturedCarouselPlaceholder />
    <TabsPlaceHolder />
    <!-- <GridLayoutPlaceholder></GridLayoutPlaceholder> -->
  </div>
</template>

<script>
export default {
  name: "detailPageLoader",
  data() {
    return {
      isEdge: false,
    };
  },
  created() {
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    this.isEdge = !isIE && !!window.StyleMedia;
  },
  components: {
    FeaturedCarouselPlaceholder: () => import(/* webpackChunkName: "featplaceholder" */ "@/components/placeholders/FeaturedCarouselPlaceholder.vue"),
    TabsPlaceHolder: () => import(/* webpackChunkName: "tabsPlaceHolder" */ "@/components/placeholders/TabsPlaceHolder.vue"),
    GridLayoutPlaceholder: () => import(/* webpackChunkName: "gridplaceholder" */ "@/components/placeholders/GridLayoutPlaceholder.vue"),
  }
};
</script>

<style lang="scss" scoped>
@import "./detailPageLoader.scss"
</style>
